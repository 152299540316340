// @ts-nocheck
import { useEffect, useState } from "react";
import { useWindowSize } from "@/hooks/useWindowSize";
import { DesignSystemComponent, GridT } from "@/types/design-system-types";
import ComponentResolver from "@/utils/system/component-resolver";
import { cn } from "@/utils/helpers";
import useLocalizedText from "@/hooks/useLocalisedText";
import { TextKey } from "@/config/i18n";

const Grid = ({
  data, // All incoming Contentful data
  debug, // Debug framer

  // Contentful controlled Props
  slots = data?.fields?.slots ?? [],
  rows = data?.fields?.rows ?? 0,
  row1Columns = data?.fields.row1Columns ?? 1,
  row2Columns = data?.fields.row2Columns ?? 0,
  row3Columns = data?.fields.row3Columns ?? 0,
  row4Columns = data?.fields.row4Columns ?? 0,
  row5Columns = data?.fields.row5Columns ?? 0,
  stretchFirstCellOnTablet = data?.fields.stretchFirstCellOnTablet ?? false,
  loadMoreThreshold = data?.fields.loadMoreThreshold,
  loadMoreIncrement = data?.fields.loadMoreIncrement,

  rowGap = data?.fields.rowGap ?? "md",
  columnGap = data?.fields.columnGap ?? "md",

  className,
  ...props
}: DesignSystemComponent<GridT>) => {
  // On load - Hide slots based on loadMoreThreshold
  const getUpdatedVisibleSlots = (slots, loadMoreThreshold) => {
    return slots.map((slot, index) => ({
      ...slot,
      fields: {
        ...slot.fields,
        hidden: loadMoreThreshold && index >= loadMoreThreshold,
      },
    }));
  };

  const showMoreText = useLocalizedText(TextKey.ShowMore);

  const [visibleSlots, setVisibleSlots] = useState(getUpdatedVisibleSlots(slots, loadMoreThreshold)); // Visible slots
  const [visibleCount, setVisibleCount] = useState(loadMoreThreshold || 0); // How many slots should be visible
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);

  // Calculate the start index for each row
  const row1End = row1Columns;
  const row2End = row1End + row2Columns;
  const row3End = row2End + row3Columns;
  const row4End = row3End + row4Columns;
  const row5End = row4End + row5Columns;

  // Allocate slots to each row
  const row1Slots = visibleSlots?.slice(0, row1End);
  const row2Slots = visibleSlots?.slice(row1End, row2End);
  const row3Slots = visibleSlots?.slice(row2End, row3End);
  const row4Slots = visibleSlots?.slice(row3End, row4End);
  const row5Slots = visibleSlots?.slice(row4End, row5End);

  const rowGapClass = `n-grid-row-gap-${rowGap}`;
  const columnGapClass = `n-grid-col-gap-${columnGap}`;

  // Stretch first cell on tablet
  const stretchFirstCellOnTabletClass = stretchFirstCellOnTablet && "sm:xl-max:col-span-2";

  // Reveal the visible slots when load more button is clicked
  const incrementVisibleSlots = (prevCount: number): number => {
    const increment = loadMoreIncrement ?? slots.length; // Default to show all slots
    const newCount = prevCount + increment;

    setVisibleSlots((prevSlots) =>
      prevSlots.map((slot, index) => ({
        ...slot,
        fields: {
          ...slot.fields,
          hidden: index >= newCount,
        },
      }))
    );

    // Hide load more button if all slots are visible
    if (newCount >= slots.length) {
      setShowLoadMoreButton(false);
    }

    return newCount;
  };

  const loadMore = () => {
    setVisibleCount(incrementVisibleSlots);
  };

  // Logic to show/hide slots based on hidden property
  useEffect(() => {
    setVisibleSlots(getUpdatedVisibleSlots(slots, loadMoreThreshold));
    setVisibleCount(loadMoreThreshold || 0);
    setShowLoadMoreButton(true);
  }, [slots, loadMoreThreshold]);

  // ! Mobile view only
  const { isMobile } = useWindowSize();
  if (isMobile) {
    return (
      <>
        {visibleSlots?.length > 0 && (
          <div className={`grid-mobile mb-4 relative grid grid-cols-1 sm:grid-cols-2 lg:hidden ${rowGapClass} ${columnGapClass}`}>
            {debug}
            {visibleSlots?.map((slot, index) => (
              slot.sys ?
              <ComponentResolver
                className={`h-full flex-1
                  ${slot.fields.hidden ? "hidden" : ""}
                  ${index === 0 ? stretchFirstCellOnTabletClass : ""}
                `}
                key={index}
                data={slot}
              /> : <div className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`} key={index}>{slot}</div>
            ))}
          </div>
        )}
        {loadMoreThreshold !== undefined && loadMoreThreshold > 0 && showLoadMoreButton && (
          <button type="button" onClick={loadMore} title="Load more" className="btn btn-primary mx-auto">{showMoreText}</button>
        )}
      </>
    );
  }

  // ! Desktop view only
  return (
    <>
      <div
        {...props}
        className={cn("n-grid relative hidden w-full lg:grid", [rowGapClass, columnGapClass, className])}
      >
        {/* DEBUGGING INFO FOR LOCAL */}
        {debug}

        {/* Row 1 */}
        {row1Slots?.length > 0 && (
          <div className={cn(`grid`, `lg:grid-cols-${row1Columns}`, [ columnGapClass])}>
            {row1Slots.map((slot, index) => (
              slot.sys ?
              <ComponentResolver
                className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`}
                key={index}
                data={slot}
              /> : <div className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`} key={index}>{slot}</div>
            ))}
          </div>
        )}

        {/* Row 2 */}
        {row2Slots?.length > 0 && (
          <div className={cn(`grid`, `lg:grid-cols-${row2Columns}`, [ columnGapClass])}>
            {row2Slots.map((slot, index) => (
              slot.sys ? <ComponentResolver
                className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`}
                key={index}
                data={slot}
              /> : <div className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`} key={index}>{slot}</div>
            ))}
          </div>
        )}

        {/* Row 3 */}
        {row3Slots?.length > 0 && (
          <div className={cn(`grid`, `lg:grid-cols-${row3Columns}`, [ columnGapClass])}>
            {row3Slots.map((slot, index) => (
              slot.sys ? <ComponentResolver
                className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`}
                key={index}
                data={slot}
              /> : <div className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`} key={index}>{slot}</div>
            ))}
          </div>
        )}

        {/* Row 4 */}
        {row4Slots?.length > 0 && (
          <div className={cn(`grid`, `lg:grid-cols-${row4Columns}`, [ columnGapClass])}>
            {row4Slots.map((slot, index) => (
              slot.sys ? <ComponentResolver
                className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`}
                key={index}
                data={slot}
              /> : <div className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`} key={index}>{slot}</div>
            ))}
          </div>
        )}

        {/* Row 5 */}
        {row5Slots?.length > 0 && (
          <div className={cn(`grid`, `lg:grid-cols-${row5Columns}`, [ columnGapClass])}>
            {row5Slots.map((slot, index) => (
              slot.sys ? <ComponentResolver
                className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`}
                key={index}
                data={slot}
              /> : <div className={`flex-1 ${slot.fields.hidden ? "hidden" : ""}`} key={index}>{slot}</div>
            ))}
          </div>
        )}

        {/* Load more button */}
        {loadMoreThreshold !== undefined && loadMoreThreshold > 0 && showLoadMoreButton && visibleSlots.length !== slots.length && (
          <button type="button" title="Load more" onClick={loadMore} className="mx-auto btn btn-primary">
            Load more
          </button>
        )}
      </div>
    </>
  );

};

export default Grid;

// n-grid-row-gap-md n-grid-col-gap-md n-grid-row-gap-lg n-grid-col-gap-lg n-grid-row-gap-xl n-grid-col-gap-xl n-grid-row-gap-section
