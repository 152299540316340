// "use client";
import { Asset } from "contentful";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import Card from "@/components/core/molecules/card";
import Grid from "@/components/core/organisms/grid";
import { TextKey } from "@/config/i18n";
import useLocalizedText from "@/hooks/useLocalisedText";
import { getRouteDetails } from "@/utils/helpers";

export type Article = {
  title: string;
  excerpt: string;
  img: Asset;
  url: string;
};

const KnowledgeHub = ({
  data,
  debug,
  include = data?.fields?.additionalSettings?.include || 20,
  loadMoreThreshold = data?.fields?.additionalSettings?.loadMoreThreshold || 6,
}: any) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [defaultImage, setDefaultImage] = useState<Asset>();

  const router = useRouter();
  const { locale } = getRouteDetails(router, true);

  const readMoreText = useLocalizedText(TextKey.ReadMore);

  // Get news articles
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // const isSSR = typeof window === "undefined";
        // const baseUrl = isSSR ? process.env.APP_URL : "";

        const response = await fetch("/api/neptune/contentful/news-articles", {
          method: "POST",
          body: JSON.stringify({ include, locale }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        // Check if response is OK before attempting to parse
        if (!response.ok) {
          console.error(
            `Error fetching articles: ${response.status} ${response.statusText}`
          );
          // Sample the response to see what we got instead of JSON
          const sampleText = await response.text();
          console.error(`Response begins with: ${sampleText.substring(0, 100)}`);
          setArticles([]);
          return;
        }

        // Parse JSON only for successful responses
        const data = await response.json();

        if (data.error) {
          console.error("Failed to fetch articles:", data.error);
          setArticles([]);
          return;
        }

        // Filter out `null` articles
        const articles = (data.articles || []).filter((article: Article | null) => {
          return !!article;
        });

        setArticles(articles);
      } catch (error) {
        console.error("Failed to fetch articles:", error);
        setArticles([]);
      }
    };

    fetchArticles();
  }, [locale, include]);

  // Get and save the default image to use if article image is not set
  useEffect(() => {
    // Only fetch default image if there is an article without an image
    if (!articles.some((article) => !article.img)) return;

    const imageId = "1IN3AyaLXVr5SEccZ9Ixlb";
    const fetchDefaultImage = async () => {
      try {
        // const isSSR = typeof window === "undefined";
        // const baseUrl = isSSR ? process.env.APP_URL : "";

        const response = await fetch("/api/neptune/contentful/asset", {
          method: "POST",
          body: JSON.stringify({
            id: imageId,
            locale,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        // Check if response is OK before attempting to parse
        if (!response.ok) {
          console.error(
            `Error fetching default image: ${response.status} ${response.statusText}`
          );
          // Sample the response to see what we got instead of JSON
          const sampleText = await response.text();
          console.error(`Response begins with: ${sampleText.substring(0, 100)}`);
          return;
        }

        // Parse JSON only for successful responses
        const data = await response.json();

        if (data.error) {
          console.error("Failed to fetch default image:", data.error);
          return;
        }

        setDefaultImage(data.data);
      } catch (error) {
        console.error("Failed to fetch default image:", error);
      }
    };

    fetchDefaultImage();
  }, [articles, locale]);

  return (
    <div className="relative">
      {debug}
      <Grid
        className="w-full"
        row1Columns={3}
        row2Columns={3}
        row3Columns={3}
        row4Columns={3}
        row5Columns={3}
        loadMoreThreshold={loadMoreThreshold}
        loadMoreIncrement={3}
        slots={articles.map((article: Article, index: number) => {
          if (!article?.img && defaultImage) {
            article.img = defaultImage;
          }
          return (
            <Card
              isClickable={true}
              key={index}
              imageSize="full"
              image={article.img}
              title={article.title}
              description={article.excerpt.slice(0, 200) + "..."}
              theme="white-90"
              supportingText={<p className="text-azure-100">{readMoreText}</p>}
              href={article.url}
              className="img-aspect-3/2"
            />
          );
        })}
      />
    </div>
  );
};

export default KnowledgeHub;
